import AddressFooter from "pages/Home/components/Footer/components/AddressFooter"
import Form from "pages/Home/components/Form"

import "./Footer.scss"

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content container">
        <img src="/icons/background/triangle.png" className="bg-triangle" />
        <img src="/icons/background/dots-green.png" className="bg-dots" />

        <div className="footer-box">
          <div className="info">
            <p className="text-info">
              Garanta a <span className="text-strong">melhor proteção</span>{" "}
              para o seu veículo, agora mesmo!
            </p>
          </div>

          <Form id="form-footer" />
        </div>
      </div>

      <AddressFooter />
    </div>
  )
}

export default Footer

import "./OurClients.scss"
import Carousel from "components/Carousel"
import OurClientsItem from "pages/Home/components/OurClients/OurClientsItem"

function OurClients() {
  const breakPoints = {
    1080: {
      slidesPerPage: 1,
    },
  }
  return (
    <div className="our-clients-container">
      {/* <div className="bg-container"></div> */}
      <div className="content">
        <Carousel hideArrows breakPoints={breakPoints} slidesPerPage={1}>
          <OurClientsItem
            image="jean-carlos-junior-de-mendonca.png"
            name="Jean Carlos Junior De Mendonça"
            message="Recebi o valor do meu veículo em pouco tempo e sem muita burocracia, adorei a experiência."
            since="Cliente desde 2019"
          />

          <OurClientsItem
            image="alberto-pereira-da-silva.png"
            name="Alberto Pereira Da Silva"
            message="Não tive nenhum problema quando precisei de auxílio, serviço excelente."
            since="Cliente desde 2017"
          />

          <OurClientsItem
            image="jose-francisco-dos-santos.png"
            name="José Francisco Dos Santos"
            message="Atendimento de primeira. Recebi meu dinheiro em pouquíssimo tempo."
            since="Cliente desde 2018"
          />

          <OurClientsItem
            image="jose-henrique-da-silva-lima.png"
            name="José Henrique Da Silva Lima"
            message="Resolvi tudo muito rápido. Sem nenhuma dor de cabeça."
            since="Cliente desde 2019"
          />

          <OurClientsItem
            image="ozana-rodrigues-souza-de-oliveira.png"
            name="Ozana Rodrigues Souza De Oliveira"
            message="Contratei a proteção veicular e eles me reembolsaram sem nenhuma burocracia, são muito transparentes. Recomendo."
            since="Cliente desde 2018"
          />
        </Carousel>

        <h2 className="our-clients-background-text">Nossos clientes</h2>
        <img
          src="/images/our-clients/double-quotes.png"
          className="our-clients-double-quotes"
        />
        <img
          src="/icons/background/triangle-offset.png"
          className="our-clients-triangle-offset"
        />
        <img src="/icons/background/dots.png" className="our-clients-dots" />
      </div>
    </div>
  )
}

export default OurClients

import "./VehicleProtection.scss"
import Carousel from "components/Carousel"
import CarouselItem from "components/CarouselItem"

function VehicleProtection() {
  return (
    <div
      className="vehicle-protection-container"
      id="vehicle-protection-section"
    >
      <h2 className="vehicle-protection-title">
        Proteção <span>veicular</span>
      </h2>
      <p className="vehicle-protection-description">
        Mantenha o seu veículo protegido com o nosso plano de proteção veicular
        que foi desenvolvido para a sua tranquilidade.
      </p>
      <Carousel hideArrows>
        <CarouselItem
          image="vehicle-protection/collision"
          title="Choque, Colisão e Capotamento"
          description="Esta proteção cobre os danos resultantes de choque, colisão ou capotamento do veículo."
        />
        <CarouselItem
          image="vehicle-protection/theft"
          title="Roubo ou Furto"
          description="Esta proteção cobre os danos provocados ao veículo ou a sua perda em caso de furto ou roubo ou tentativa de furto ou roubo, no limite do valor do veículo."
        />
        <CarouselItem
          image="vehicle-protection/explosion"
          title="Incêndio ou Explosão"
          description="Garante os prejuízos sofridos pelo veículo protegido provocados por Incêndio ou Explosão provinientes de abalroamento/colisão."
        />
        <CarouselItem
          image="vehicle-protection/glasses"
          title="Vidros, fárois, lanternas e retrovisores."
          description="Garante o pára-brisa de seu veículo resguardado contra quebras e rachaduras, além dos vidros, protejemos também os faróis, lanternas e retrovisores contra quebras e outros infortuitos."
        />
        <CarouselItem
          image="vehicle-protection/reserve"
          title="Carro reserva"
          description="Benefício adicional, que é oferecido aos nossos associados que tiverem interesse em possuir uma cobertura ainda mais completa. "
        />
        <CarouselItem
          image="vehicle-protection/third"
          title="Proteção contra terceiros"
          description="Cobertura que oferecemos uma proteção ao motorista, caso seja o mesmo seja o responsável por algum sinistro e que isso cause prejuízos a outros."
        />
        <CarouselItem
          image="vehicle-protection/tracking"
          title="Rastreamento e Monitoramento"
          description="O rastreador recebe as informações, trata e emite-as de volta para os servidores da Central de Monitoramento 24 horas, concedendo aos associados as informações via internet em tempo real."
        />
      </Carousel>
    </div>
  )
}

export default VehicleProtection

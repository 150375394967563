import React, { useState } from "react"

import "./Toggle.scss"

function Toggle({ direction, question, children }) {
  const [arrowDirection, setArrowDirection] = useState(direction || "down")
  const [isPanelOpen, setPanelOpened] = useState(false)

  function changeArrow() {
    if (arrowDirection === "down") {
      setPanelOpened(true)
      setArrowDirection("up")
    } else {
      setPanelOpened(false)
      setArrowDirection("down")
    }
  }

  return (
    <div className="toggle-content-items">
      <div
        className={`header ${isPanelOpen && "opened-toggle"}`}
        onClick={changeArrow}
      >
        <span>{question}</span>
        <div className={`arrow ${arrowDirection}`}></div>
      </div>
      <div className={`body ${isPanelOpen && "opened"}`}>{children}</div>
    </div>
  )
}

export default Toggle

import { createContext, useContext, useReducer } from "react"

const UserDataContext = createContext()

const reducer = (prevState, state) => ({
  ...prevState,
  ...state,
})

const errorUserData = {
  email: "managerwpp@gmail.com",
  phone: "12839172391",
  name: "Erro na solicitacao",
  fbId: null,
}

const initialState = {
  usersData: {
    "/es/d": {
      email: "diana.es@vipseg.org",
      phone: "5527997113636",
      name: "Diana",
      fbId: "247020956634113",
    },

    "/ba/n": {
      email: "naiara.ba@vipseg.org",
      phone: "5575998731711",
      name: "Naiara",
      fbId: "1261071140910058",
    },
    "/al/s": {
      email: "sergio.al@vipseg.org",
      phone: "5581995779939",
      name: "Sergio",
      fbId: "892047701316352",
    },
    "/pb/f": {
      email: "felix.pb@vipseg.org",
      phone: "5581986073128",
      name: "Felix",
      fbId: "244626083506147",
    },

    "/rn/j": {
      email: "john.rn@vipseg.org",
      phone: "5581998419545",
      name: "John",
      fbId: "599457690682534",
    },

    "/pe/j": {
      email: "jailson.pe@vipseg.org",
      phone: "5581985857849",
      name: "Jailson",
      fbId: "332680354401434",
    },

    "/pe/l": {
      email: "leonardo.pe@vipseg.org",
      phone: "5581‎981255448",
      name: "Leonardo",
      fbId: "465941377562671",
    },

    "/pe/m": {
      email: "magalhaes.pe@vipseg.org",
      phone: "5581984532112",
      name: "Magalhaes",
      fbId: "260863664989726",
    },

    "/matriz/t": {
      email: "tiago@vipseg.org",
      phone: "558141041262",
      name: "Tiago",
      fbId: "650198798976880",
    },

    default: errorUserData,
  },
  userData: errorUserData,
}

function UserDataProvider({ children }) {
  const [state, setState] = useReducer(reducer, initialState)

  return (
    <UserDataContext.Provider value={[state, setState]}>
      {children}
    </UserDataContext.Provider>
  )
}

function useUserDataState() {
  return useContext(UserDataContext)
}

export { useUserDataState, UserDataProvider }

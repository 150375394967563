import "./MakeAQuote.scss"

function MakeAQuote() {
  return (
    <div className="make-a-quote-container">
      <img className="make-a-quote-stairs" src="/icons/background/stairs.png" />
      <img className="make-a-quote-dots" src="/icons/background/dots.png" />
      <a className="make-a-quote-btn" href="#form-footer">
        Faça uma cotação agora
      </a>
    </div>
  )
}

export default MakeAQuote

import "./Layout.scss"
import { useUserDataState } from 'contexts/userData'
import { useEffect } from 'react'

function Layout({ children, path }) {
  const [state, setState] = useUserDataState()
  const { usersData } = state

  const userData = usersData[path] || usersData["default"]

  console.log({ state, path, userData })

  useEffect(() => {
    setState({
      userData
    })
  }, [])
  
  return <div className="default-layout">{children}</div>
}
export default Layout

import "./Carousel.scss"
import Arrow from "components/Arrow"
import ReactCarousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

const withArrowProps = {
  arrowLeft: <Arrow direction="left" />,
  arrowLeftDisabled: <div />,
  arrowRight: <Arrow direction="right" />,
  arrowRightDisabled: <div />,
  addArrowClickHandler: true,
}

const defaultBreakPoints = {
  680: {
    slidesPerPage: 1,
  },
  900: {
    slidesPerPage: 2,
  },
}

const withoutArrowsProps = {
  arrows: false,
}

function Carousel({
  breakPoints = defaultBreakPoints,
  hideArrows = false,
  hideDots = false,
  children,
  ...props
}) {
  const arrowsProps = hideArrows ? withoutArrowsProps : withArrowProps
  return (
    <ReactCarousel
      breakpoints={breakPoints}
      slidesPerPage={3}
      infinite
      {...arrowsProps}
      dots={!hideDots}
      {...props}
    >
      {children}
    </ReactCarousel>
  )
}

export default Carousel

import axios from "axios"

async function sendQuoteEmail(form = {}) {
  try {
    const response = await axios.post(
      "https://cors-proxy.htmldriven.com/?url=/https://vipseg.vercel.app/api/send-email",
      form
    )
    return response
  } catch (e) {
    return e
  }
}

export default sendQuoteEmail

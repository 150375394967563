import "./Hero.scss"
import Arrow from "components/Arrow"
import Form from "pages/Home/components/Form"

function Hero() {
  return (
    <div className="hero-container">
      <img
        src="/images/il/hero-complete-tree.png"
        alt=""
        className="hero-complete-tree"
      />
      <div className="hero-il">
        <h1 className="hero-title">
          Proteção total para o seu{" "}
          <span>
            <u>veículo</u>.
          </span>
        </h1>
        <img src="/images/il/il-home.png" />
      </div>
      <Form />
      <div className="checkout-protections-container">
        <p className="checkout-text">Confira nossas proteções</p>
        <Arrow href="#vehicle-protection-section" />
      </div>
    </div>
  )
}

export default Hero

import "./Background.scss"

function Background() {
  return (
    <div className="background-container">
      <img className="hero-triangle" src="/icons/background/triangle.png" />
      <img
        className="protection-triangle-offset"
        src="/icons/background/triangle-offset.png"
      />
    </div>
  )
}

export default Background

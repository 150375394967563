import "./Error.scss"

function Error({ error, className }) {
  const { message } = error || {}

  return !error ? (
    <div className="error-empty"></div>
  ) : (
    <span className={`error ${className}`}>{message}</span>
  )
}

export default Error

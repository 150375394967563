import "./CarouselItem.scss"

function CarouselItem({ image, title, description }) {
  return (
    <div className="carousel-item-container">
      <div className="carousel-item-image">
        <img src={`/images/${image}.png`} />
      </div>
      <h3 className="carousel-item-title">{title}</h3>
      <p className="carousel-item-description">{description}</p>
    </div>
  )
}

export default CarouselItem

import { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import InputMask from "react-input-mask"

import Error from "components/Error"
import Button from "components/Button"

import sendQuoteEmail from "services/quote.service"

import "./Form.scss"
import { useUserDataState } from "contexts/userData"

function Form({ id }) {
  const [state] = useUserDataState()

  const { userData } = state
  const defaultValues = {
    name: "",
    phone: "",
    lisencePlate: "",
    email: "",
  }

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitted },
    control,
  } = useForm({ defaultValues })

  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function getFieldClass(fieldName) {
    if (!isSubmitted) {
      return "form-row"
    }

    const hasError = errors[fieldName]

    if (hasError) {
      return "form-row form-row__error"
    } else {
      return "form-row form-row__success"
    }
  }

  function onReset() {
    reset(defaultValues)
    setSuccess(false)
  }

  async function onSubmit(data) {
    setIsLoading(true)

    const { lisencePlate } = data

    const {
      data: { success },
    } = await sendQuoteEmail({
      ...data,
      lisencePlate: lisencePlate.toUpperCase(),
      destinyEmail: userData?.email,
    })

    const hasFbPixel = typeof window !== "undefined" && window.fbq != null
    if (hasFbPixel && success) {
      window.fbq("track", "PageView")
    }
    setSuccess(success)
    setIsLoading(false)
  }

  return (
    <div className="form-container" id={id}>
      <div className={success ? "overlay" : "hide"}>
        <img src="/icons/form/check-success.png" />
        <p className="overlay-text">
          Um de nossos especialistas irá entrar em contato com você em alguns
          minutos!
        </p>
        <button className="overlay-btn" onClick={onReset}>
          Fazer uma outra cotação
        </button>
      </div>
      <div className={success ? "hide" : "form-content"}>
        <div className="header-form">
          <span className="title-form">Faça sua cotação!</span>
          <p className="subtitle-form">
            Preencha o formulário e receba uma cotação com o melhor preço em{" "}
            <span className="subtitle-form strong">5 minutos</span>.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="content-form">
            <div className={getFieldClass("name")}>
              <input
                id="name"
                name="name"
                type="text"
                className="input "
                placeholder="Nome"
                ref={register({
                  required: "Campo obrigatório",
                  minLength: {
                    value: 3,
                    message: "Preencha o nome corretamente!",
                  },
                })}
              />
              <Error error={errors?.name} className="input-name-error" />
            </div>
            <div className={getFieldClass("email")}>
              <input
                id="email"
                name="email"
                className="input"
                type="email"
                placeholder="E-mail"
                ref={register({
                  required: "Campo obrigatório",
                  pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                    message: "Preenchar o e-mail corretamente!",
                  },
                })}
              />
              <Error error={errors?.email} className="input-email-error" />
            </div>
            <div className={getFieldClass("phone")}>
              <Controller
                as={InputMask}
                control={control}
                className="input input-phone"
                placeholder="Celular"
                mask="99 9 9999 9999"
                maskChar=""
                name="phone"
                type="telephone"
                rules={{
                  required: "Campo obrigatório",
                  minLength: {
                    value: 14,
                    message: "Preencha o celular corretamente!",
                  },
                }}
              />

              <Error error={errors?.phone} className="input-phone-error" />
            </div>
            <div className={getFieldClass("lisencePlate")}>
              <Controller
                as={InputMask}
                control={control}
                id="lisencePlate"
                name="lisencePlate"
                type="text"
                className="input input-car"
                placeholder="Placa do veículo"
                mask="*** ****"
                maskChar=""
                rules={{
                  required: "Campo obrigatório",
                  minLength: {
                    value: 8,
                    message: "Preencha a placa do veículo corretamente!",
                  },
                }}
              />
              <Error
                error={errors?.lisencePlate}
                className="input-lisence-plate-error"
              />
            </div>
            <div className="footer-form">
              <Button isLoading={isLoading} text="FAZER UMA COTAÇÃO" />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Form

import React from "react"

import "./Toggle.scss"

function ListToggle({ children }) {
  return (
    <div className="toggle-container ">
      <div className="toggle-content container">{children}</div>
    </div>
  )
}

export default ListToggle

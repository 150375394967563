import "./Header.scss"
import Social from "components/Social"

function Header() {
  return (
    <div className="header-container">
      <img src="/images/logo.png" />
      <div className="social-items">
        <Social name="wpp" />
        <Social name="facebook" />
        <Social name="instagram" />
      </div>
    </div>
  )
}

export default Header

import "./Social.scss"
import { useUserDataState } from 'contexts/userData'

function Social({ name, transparent = false }) {
  const [state] = useUserDataState()
  const { userData } = state

  const src = `/icons/social/${name}.png`
  const transparentClass = transparent ? "without-background" : ""
  const socialContainerClass = `social-container ${name} ${transparentClass}`
  const links = {
    wpp: `https://api.whatsapp.com/send?l=pt&phone=${userData?.phone}&text=Olá especialista, gostaria de solicitar uma cotação.`,
    facebook: "https://www.facebook.com/vipsegbrasil/",
    instagram: "https://www.instagram.com/vipsegbrasil/?hl=pt-br",
  }
  const href = links[name]

  return (
    <a className={socialContainerClass} href={href} target="_blank">
      <img className="social-icon" src={src} />
    </a>
  )
}

export default Social

import Social from "components/Social"

import "./AddressFooter.scss"

function AddressFooter() {
  return (
    <div className="address-container">
      <div className="address-content">
        <div className="address">
          <p>VipSeg Brasil Clube de Benefícios e Proteção Veícular</p>
          <p>
            CNPJ: 36.684.733/0001-30 - Avenida Montevidéu, nº 260, RECIFE/PE -
            CEP: 50050-250
          </p>
        </div>
        <div className="social">
          <Social name="wpp" transparent />
          <Social name="facebook" transparent />
          <Social name="instagram" transparent />
        </div>
      </div>
    </div>
  )
}

export default AddressFooter

import Loading from "components/Loading"

import "./Button.scss"

function Button({ isLoading, text }) {
  return (
    <div>
      <button id="btn-submit" type="submit" className="btn-submit">
        {isLoading && <Loading />} {text}
      </button>
    </div>
  )
}

export default Button

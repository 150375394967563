function OurClientsItem({ image, message, name, since }) {
  const imageUrl = `/images/our-clients/${image}`
  return (
    <div className="our-clients-item-container">
      <div className="our-clients-item-image">
        <img src={imageUrl} />
      </div>
      <div className="our-clients-item-content">
        <h3 className="our-clients-item-message">{message}</h3>
        <p className="our-clients-item-name">{name}</p>
        <p className="our-clients-item-since">{since}</p>
      </div>
    </div>
  )
}

export default OurClientsItem

import Layout from "components/Layout"
import Assistance from "pages/Home/components/Assistance"
import Background from "pages/Home/components/Background"
import Footer from "pages/Home/components/Footer"
import Header from "pages/Home/components/Header"
import Hero from "pages/Home/components/Hero"
import MakeAQuote from "pages/Home/components/MakeAQuote"
import MetaTags from "pages/Home/components/MetaTags"
import OurClients from "pages/Home/components/OurClients"
import QuestionsProtection from "./components/QuestionsProtection"
import VehicleProtection from "pages/Home/components/VehicleProtection"
import { UserDataProvider } from "contexts/userData"

const HomePage = ({ path }) => {
  return (
    <UserDataProvider>
      <Layout path={path}>
        <MetaTags />
        <Background />

        <Header />

        <Hero />

        <VehicleProtection />

        <MakeAQuote />

        <Assistance />

        <OurClients />

        <QuestionsProtection />

        <Footer />
      </Layout>
    </UserDataProvider>
  )
}

export default HomePage

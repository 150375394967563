import { Helmet } from "react-helmet"
import FacebookPixel from "./FacebookPixel"

function MetaTags() {
  return (
    <>
      <Helmet>
        <title>Vipseg</title>
      </Helmet>
      <FacebookPixel />
    </>
  )
}

export default MetaTags

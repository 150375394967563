import "./Assistance.scss"
import Carousel from "components/Carousel"
import CarouselItem from "components/CarouselItem"

function Assistance() {
  return (
    <div className="assistance-container container">
      <h2 className="assistance-title">
        Assistência <span>24hrs</span>
      </h2>
      <p className="assistance-description">
        Conte com uma gama de serviços que podem ser solicitados 24hrs por dia,
        7 dias por semana. A tranquilidade de estar sempre protegido.
      </p>
      <Carousel hideDots>
        <CarouselItem
          image="assistance/trailer"
          title="Reboque 24hrs"
          description="O rastreador recebe as informações, trata e emite-as de volta para os servidores da Central de Monitoramento 24 horas, concedendo aos associados as informações via internet em tempo real."
        />
        <CarouselItem
          image="assistance/taxi"
          title="Táxi"
          description="O associado tem o pára-brisa de seu veículo resguardado contra quebras e rachaduras, além dos vidros, protejemos também os faróis, lanternas e retrovisores contra quebras e outros infortuitos."
        />
        <CarouselItem
          image="assistance/locksmith"
          title="Chaveiro"
          description="Este é um benefício adicional, que é oferecido aos nossos associados que tiverem interesse em possuir uma cobertura ainda mais completa."
        />
        <CarouselItem
          image="assistance/accomodation"
          title="Hospedagem"
          description="Só em caso de acidente, roubo ou furto ocorrido até 50 km do domicílio cadastrados no sistema interno da Vipseg."
        />
      </Carousel>

      <img src="/images/assistance/line.png" className="assistance-line" />
      <img src="/images/assistance/tree.png" className="assistance-tree" />
      <img
        src="/icons/background/triangle.png"
        className="assistance-triangle"
      />
    </div>
  )
}

export default Assistance

import "./Arrow.scss"

function Arrow({ direction = "down", ...props }) {
  return (
    <a className={`arrow-container ${direction}`} {...props}>
      <div className="arrow-icon-wrapper">
        <img className="arrow-icon" src="/icons/arrow.svg" />
      </div>
    </a>
  )
}

export default Arrow

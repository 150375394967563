import React from "react"

import ListToggle from "components/Toggle/ListToggle"
import Toggle from "components/Toggle/Toggle"

import "./QuestionsProtection.scss"

function QuestionsProtection() {
  return (
    <div className="questions-container">
      <div className="bg-container"></div>

      <div className="content">
        <div className="info">
          <img
            src="/icons/background/triangle-offset.png"
            className="bg-triangle"
          />
          <img src="/icons/background/dots.png" className="bg-dots" />
          <span className="info-title">
            <strong className="strong-red">dúvidas</strong> sobre a nossa
            proteção
          </span>

          <p className="info-text">
            Confira uma sessão de dúvidas e repostas que você possa ter sobre a
            nossa proteção e entenda perfeitamente como ele funciona.
          </p>
        </div>

        <ListToggle>
          <Toggle
            direction={"down"}
            question={"Proteção Veicular é uma atividade legalizada?"}
          >
            <p>
              Sim, a Proteção Veicular é legalizada. A Constituição Federal
              garante o direito à livre associação, o que legitima a atuação das
              associações para fins de proteção veicular. Os principais
              tribunais do país já se pronunciaram sobre essa questão e a
              maioria reconhece a legalidade da atuação dessas instituições.
              Portanto, não há o que temer! Fique tranquilo ao contratar a
              Proteção Veicular para o seu veículo.
            </p>
          </Toggle>
          <Toggle
            direction={"down"}
            question={"O que a proteção veicular cobre?"}
          >
            <p>
              Na proteção veicular, as coberturas dos veículos é muito parecida
              com a cobertura oferecida pelo seguro automotivo, que são: Roubo,
              Furto, Colisão, Incêndio, Assistência 24 horas, Carro reserva,
              Fundo para terceiros de até 70 mil reais, proteção dos vidros em
              geral, rastreamento e monitoramento, entre outros. E ainda o
              associado conta com uma gama de benefícios.
            </p>

            <p>
              Suas dúvidas foram solucionadas? Caso ainda tenha restado alguma
              dúvida, entre em contato conosco.
            </p>
          </Toggle>
          <Toggle
            direction={"down"}
            question={"Em quanto tempo meu veículo está protegido?"}
          >
            <p>
              A primeira coisa a ser feita após o contrato é a vistoria do
              veículo. Feito isso, em até 24h seu veículo estará protegido. Esta
              também é uma das vantagens da Proteção Veicular. Tudo ocorre de
              forma mais simples e rápida do que em um seguro tradicional.
            </p>
          </Toggle>
          <Toggle direction={"down"} question={"Como fazer para se associar?"}>
            <p>
              Para se associar à Proteção Veicular pode fazer contato por meio
              do telefone, pessoalmente ou através do site. Para fazer um
              contato com a VipSeg, basta preencher o formulário no site e
              nós entraremos em contato.
            </p>
          </Toggle>
          <Toggle direction={"down"} question={"Há restrição de perfil?"}>
            <p>
              Não. E esse é, talvez, o maior diferencial das associações de
              proteção veicular. As empresas convencionais levantam uma série de
              questões na hora de realizar o cálculo da apólice e alguns perfis
              não são aceitos. Isso acontece porque a empresa arca com todos os
              custos em caso de eventos, portanto, dá preferência aos perfis
              menos propensos a roubos, furtos ou colisões.
            </p>
            <p>
              Nas associações de proteção veicular, como é levado em conta
              apenas o valor do veículo determinado pela tabela FIPE, são
              aceitos todos os perfis. Incluindo aqueles que têm alguma
              restrição junto ao SPC ou SERASA.
            </p>
          </Toggle>
          <Toggle
            direction={"down"}
            question={"Há taxa de adesão e vistoria do veículo?"}
          >
            <p>
              Sim. Na hora de se associar, o motorista deve pagar uma taxa de
              adesão (que é única, não tendo relação com os pagamentos mensais)
              e submeter o veículo a uma vistoria.
            </p>
          </Toggle>
        </ListToggle>
      </div>
    </div>
  )
}

export default QuestionsProtection
